import { Outlet, useFetcher, useLoaderData, useLocation, useNavigate } from '@remix-run/react';
import * as Sentry from '@sentry/browser';
import { FeatureFlagsProvider } from 'apps/caredash/app/hooks/useFeatureFlags';
import useMixpanel from 'apps/caredash/app/hooks/useMixpanel';
import { type ReactElement, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { type IUserInfo } from 'types/mylaurel/IUserInfo';
import getUserInfo from '~/utils/getUserInfo';
import { myLaurel } from '~/services/mylaurel';
import VerticalNavbar from '~/components/VerticalNavbar';
import HeaderTopBar from '~/components/HeaderTopBar';

export async function loader({ request }: LoaderFunctionArgs): Promise<{
  navigation: Array<{ name: string; href: string }>;
  user: IUserInfo;
  appVersion: string | undefined;
  appEnv: string | undefined;
  partnerNames: string[];
}> {
  // Get the user info from the session
  const user = await getUserInfo(request);

  const navigation = [
    {
      name: 'Report event',
      href: 'https://docs.google.com/forms/d/e/1FAIpQLSeNkMGQ79GqoTcmzT0ZhBEWP4tRD2geDALxlCRo92kHD9gJbA/viewform',
    },
    {
      name: 'Sign out',
      href: `${process.env.AUTH_DOMAIN}/logout?client_id=${
        process.env.AUTH_CLIENT_ID
      }&logout_uri=${encodeURIComponent(process.env.BASE_URL + '/logout')}`,
    },
  ];

  return {
    navigation,
    user,
    appVersion: process.env?.npm_package_version,
    appEnv: process.env?.APP_ENV,
    partnerNames: myLaurel.fetchAllPartnerNames(),
  };
}

// Never reloading the dashboard route since these don't change
export const shouldRevalidate = (): boolean => false;

export default function Dashboard(): ReactElement {
  const location = useLocation();
  const { navigation, user, appVersion, appEnv, partnerNames } = useLoaderData<typeof loader>();
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const mixpanel = useMixpanel();

  // refresh and idle timeout in milliseconds
  const refreshInterval = 1000 * 60 * 29; // 29 minutes
  const idleTimeout = 1000 * 60 * 30; // 30 minutes

  mixpanel.init('c137cd0f2bae55889b68db0b608f05c8', { track_pageview: true });

  // Disable mixpanel in everything but production
  if (appEnv !== 'production') {
    mixpanel.disable();
  } else {
    // Identify the user in mixpanel
    mixpanel.identify(user?.email ?? '');
    mixpanel.people.set_once({ Role: user?.groups[0] });
  }

  Sentry.setUser({ email: user?.email, ip_address: '{{auto}}' });

  // Listen for location changes and track them in mixpanel
  useEffect(() => {
    mixpanel.track_pageview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Setup token refresh
  useEffect(() => {
    const refreshToken = (): void => {
      fetcher.load('/auth/refresh?next=' + location.pathname);
    };

    // Get a fresh token on page load
    refreshToken();

    // Refresh the token every 29 minutes while on the page
    const interval = setInterval(refreshToken, refreshInterval); // 29 minutes

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onIdle = (): void => {
    navigate('/logout');
  };

  // Log the user out if they are idle for 30 minutes
  useIdleTimer({
    onIdle,
    timeout: idleTimeout,
  });

  return (
    <FeatureFlagsProvider>
      <div className="flex">
        <VerticalNavbar user={user} partnerNames={partnerNames} />
        <div className="flex flex-col w-full">
          <HeaderTopBar user={user} version={appVersion} profileOptions={navigation} />
          <Outlet />
        </div>
        {appEnv !== 'production' ? (
          <div className="fixed -top-12 -left-12 bg-red-600 text-xs text-white p-2 z-50 h-24 leading-tight font-mono text-center -rotate-45 w-24 flex flex-col justify-between">
            <i></i>
            <div className="text-center w-full">
              <b>DEV</b>
            </div>
          </div>
        ) : null}
      </div>
    </FeatureFlagsProvider>
  );
}
