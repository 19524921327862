import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Fragment, type ReactElement } from 'react';
import type { IUserInfo } from 'types/mylaurel/IUserInfo';

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

interface HeaderTopBarProps {
  user: IUserInfo | undefined;
  version: string | undefined;
  profileOptions: Array<{ name: string; href: string }>;
}

export default function HeaderTopBar({ user, version, profileOptions }: HeaderTopBarProps): ReactElement {
  const groups = user?.groups ? user.groups.join(', ') : '';

  return (
    <div className="sticky z-50 top-0 flex items-center bg-gray-100">
      <div className="h-12 ml-auto flex items-center justify-center">
        <span className="font-bold pr-3 text-[12px]">
          {user?.name?.split(' ')?.map((name, index) => (index === 0 ? name[0] : name))}
        </span>
        <Menu as="div" className="relative flex justify-center items-center pr-6">
          <div>
            <MenuButton className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              {(user?.picture && (
                <img src={user?.picture} alt="Profile" className="h-[18px] w-[18px] rounded-full" />
              )) || (
                <span>
                  <span className="sr-only">Open user menu</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                    />
                  </svg>
                </span>
              )}
            </MenuButton>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute top-8 right-0 z-20 mt-2 w-64 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="block px-4 py-2 text-slate-700">
                <span className="text-sm text-slate-400">{user?.email}</span>
                <br />
                <span className="text-slate-400 text-xs">{groups.toUpperCase()}</span>
                <br />
                <span className="text-slate-400 text-xs">v{version}</span>
              </div>
              {profileOptions.map(item => (
                <MenuItem key={item.name}>
                  {({ focus }) => (
                    <a href={item.href} className={classNames(focus ? 'bg-slate-100' : '', 'block px-4 py-2 text-sm')}>
                      {item.name}
                    </a>
                  )}
                </MenuItem>
              ))}
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
