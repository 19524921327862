import { type ReactElement, useEffect, useRef, useState } from 'react';
import { Link, useNavigation } from '@remix-run/react';
import Tooltip from '~/components/tailgrids/Tooltip';
import { LucideBugPlay, LucideHeadset, LucideUserRoundPlus } from 'lucide-react';
import * as Sentry from '@sentry/remix';

export default function NavActionShelf({
  handleCreatePatientOnClick,
  isCollapsed,
}: {
  handleCreatePatientOnClick: () => void;
  isCollapsed: boolean;
}): ReactElement {
  const navigation = useNavigation();
  const [feedback, setFeedback] = useState(Sentry.getFeedback());
  const buttonRef = useRef();
  // Read `getFeedback` on the client only, to avoid hydration errors during server rendering
  useEffect(() => {
    setFeedback(Sentry.getFeedback());
  }, []);

  useEffect(() => {
    if (feedback) {
      const unsubscribe = feedback.attachTo(buttonRef.current ?? '', {
        triggerLabel: 'Report',
        formTitle: 'Report an issue',
        messagePlaceholder: 'Please describe the issue you encountered. Include steps to reproduce if possible.',
        colorScheme: 'light',
        showBranding: false,
        isNameRequired: true,
      });
      return unsubscribe;
    }
    return () => {};
  }, [feedback]);

  return (
    <div
      className={`${isCollapsed ? 'flex flex-col space-y-3 justify-center items-center p-2' : 'flex space-x-3 justify-center items-center p-4'}`}
    >
      <Tooltip tooltipsText="Log a Call" position={isCollapsed ? 'right' : 'bottom'} variant="navbar">
        <Link to="/actions/create?source=actions" reloadDocument>
          <button
            className="icon-btn border-none bg-slate-300 bg-opacity-15 hover:cursor-pointer"
            disabled={navigation.state === 'loading'}
          >
            <LucideHeadset className="w-6 h-6 text-white stroke-[1.5px]" />
          </button>
        </Link>
      </Tooltip>
      <Tooltip tooltipsText="Create Patient" position={isCollapsed ? 'right' : 'bottom'} variant="navbar">
        <button
          className="icon-btn border-none bg-slate-300 bg-opacity-15 hover:cursor-pointer"
          onClick={handleCreatePatientOnClick}
        >
          <LucideUserRoundPlus className="w-6 h-6 text-white stroke-[1.5px]" />
        </button>
      </Tooltip>
      <Tooltip tooltipsText="Report a Bug" position={isCollapsed ? 'right' : 'bottom'} variant="navbar">
        <button
          id="Sentry-Report"
          type="button"
          ref={buttonRef}
          className="icon-btn border-none bg-slate-300 bg-opacity-15 hover:cursor-pointer"
        >
          <LucideBugPlay className="w-6 h-6 text-white stroke-[1.5px]" />
        </button>
      </Tooltip>
    </div>
  );
}
