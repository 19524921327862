import { createContext, useContext, useEffect, useState } from "react";

interface FeatureFlags {
  providerScreening: boolean;
}

// Create our context
const FeatureFlagsContext = createContext<FeatureFlags | null>(null);

// Create our provider component
const FeatureFlagsProvider = ({ children }) => {
  const [flags, setFlags] = useState<FeatureFlags | null>(null);

  useEffect(() => {
    setFlags({
      providerScreening: true,
    });

    // Fetch flags from aws (TODO: could be stord somewhere else, discuss)
    // fetch("https://dev-pcc-feature-flags.s3.amazonaws.com/flags.json")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // Store the flags in local storage for backup
    //     localStorage.setItem("featureFlags", JSON.stringify(data));
    //     setFlags(data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching feature flags:", error);

    //     // If there's an error fetching the flags, try to load from local storage
    //     const storedFlags = localStorage.getItem("featureFlags");
    //     if (storedFlags) {
    //       setFlags(JSON.parse(storedFlags));
    //     } else {
    //       // If there's no flags in local storage, set the defaults (TODO: should we do this here or as the default for the context?)
    //       setFlags({
    //         providerScreening: false,
    //       });
    //     }
    //   });
  }, []);

  // Return null if the flags haven't loaded yet
  if (!flags) {
    return null;
  }

  return <FeatureFlagsContext.Provider value={flags}>{children}</FeatureFlagsContext.Provider>;
};

// Create a hook to use the flags
const useFeatureFlags = (): FeatureFlags | null => {
  return useContext(FeatureFlagsContext);
};

export { FeatureFlagsProvider, useFeatureFlags };
