import { type ReactElement, useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';

import { type IUserInfo } from 'types/mylaurel/IUserInfo';

import { CreateOrUpdatePatientFormModal } from '~/routes/__dashboard/components/Patients/CreateOrUpdatePatientFormModal';
import Navigation from '~/components/Navigation';
import NavActionShelf from '~/components/NavActionShelf';

export default function VerticalNavbar({
  user,
  partnerNames,
}: {
  user: IUserInfo | undefined;
  partnerNames: string[];
}): ReactElement {
  // Retrieve the saved state from local storage
  const savedState: string | null = localStorage.getItem('isNavbarCollapsed');
  const defaultState: boolean = savedState ? JSON.parse(savedState) : false;

  const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultState || false);
  const [showEditPatientModal, setShowEditPatientModal] = useState<boolean>(false);

  // Check the screen size and adjust the Navbar state
  useEffect(() => {
    // Function to update the collapse state based on screen size
    const updateCollapseState = (): void => {
      const isMdOrSmaller = window.matchMedia('(max-width: 767px)').matches;
      if (isMdOrSmaller) {
        setIsCollapsed(true);
      } else {
        const savedState: string | null = localStorage.getItem('isNavbarCollapsed');
        const savedStateParsed: boolean = savedState ? JSON.parse(savedState) : false;
        setIsCollapsed(savedStateParsed);
      }
    };

    // Update the state on initial load
    updateCollapseState();

    // Add event listener for screen size changes
    window.addEventListener('resize', updateCollapseState);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateCollapseState);
    };
  }, []);

  // Save the Navbar state to localStorage whenever it changes
  useEffect(() => {
    if (!window.matchMedia('(max-width: 767px)').matches) {
      localStorage.setItem('isNavbarCollapsed', JSON.stringify(isCollapsed));
    }
  }, [isCollapsed]);

  return (
    <>
      <div
        className={`sticky top-0 left-0 h-screen z-50 shrink-0 transition-all duration-400 ease-in-out ${isCollapsed ? 'w-[4.5rem]' : 'w-52'}`}
      >
        <Disclosure as="nav" className="h-full w-full border-b-1 border-indigo-900 bg-indigo-900">
          {({ open }) => (
            <div className="flex h-full w-full">
              <div className="flex flex-col w-full justify-between items-center">
                <Navigation
                  user={user}
                  partnerNames={partnerNames}
                  isCollapsed={isCollapsed}
                  setIsCollapsed={setIsCollapsed}
                />

                <div className={`flex flex-col w-full justify-center ${isCollapsed ? 'mb-2' : 'mb-8'}`}>
                  <div className="my-2 border-t border-gray-300 border-opacity-15"></div>
                  <NavActionShelf
                    handleCreatePatientOnClick={() => {
                      setShowEditPatientModal(true);
                    }}
                    isCollapsed={isCollapsed}
                  />
                </div>
              </div>
            </div>
          )}
        </Disclosure>
      </div>
      {showEditPatientModal ? (
        <CreateOrUpdatePatientFormModal
          partnerNames={partnerNames || ''}
          isOpen={showEditPatientModal}
          onHide={() => {
            setShowEditPatientModal(false);
          }}
          className="overflow-y-auto h-full w-full"
        />
      ) : null}
    </>
  );
}
